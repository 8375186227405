<template>
    <ImageSet
        #default="{ height, width, src, srcset, avifSrcset, webpSrcset }"
        :image="image"
    >
        <picture
            class="image-asset"
            v-bind="$attrs"
        >
            <source
                v-if="avifSrcset"
                type="image/avif"
                :sizes="sizes"
                :srcset="avifSrcset"
                :width="width"
                :height="height"
            >
            <source
                v-if="webpSrcset"
                type="image/webp"
                :sizes="sizes"
                :srcset="webpSrcset"
                :width="width"
                :height="height"
            >
            <img
                class="image-asset__image"
                :style="style"
                :src="src"
                :sizes="sizes"
                :srcset="srcset"
                :alt="imageAlt || ''"
                :loading="loading"
                :width="width"
                :height="height"
                :decoding="decoding"
                :fetchPriority="fetchPriority"
                v-bind="$attrs"
            >
        </picture>
    </ImageSet>
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            required: true
        },
        sizes: {
            type: String,
            default: '(min-width: 768px) 1020px, 100vw'
        },
        loading: {
            type: String,
            default: 'lazy',
            validator: value => ['lazy', 'eager'].includes(value)
        },
        decoding: {
            type: String,
            default: 'async',
            validator: value => ['sync', 'async', 'auto'].includes(value)
        },
        fetchPriority: {
            type: String,
            default: 'auto',
            validator: value => ['high', 'low', 'auto'].includes(value)
        },
        imageAlt: {
            type: String,
            default: ''
        }
    },
    computed: {
        hasFocalPoint() {
            return this.image?.hasFocalPoint ?? false;
        },
        focalPoint() {
            return this.image?.focalPoint;
        },
        objectFitPositionX() {
            return (this.focalPoint?.[0] ?? 0) * 100;
        },
        objectFitPositionY() {
            return (this.focalPoint?.[1] ?? 0) * 100;
        },
        objectFitPosition() {
            return `${this.objectFitPositionX}% ${this.objectFitPositionY}%`;
        },
        style() {
            return {
                'object-position': this.hasFocalPoint ? this.objectFitPosition : null
            };
        }
    }
};
</script>

<style src="./ImageAsset.less" lang="less" />
