<template>
    <div
        class="base-spinner"
        role="alert"
    >
        <div class="sr-only">Loading</div>
        <div />
        <div />
        <div />
        <div />
    </div>
</template>

<style lang="less" src="./BaseSpinner.less" />
